/**
* Created by tudou on 2020/4/25 16:14.
*/
<template>
  <div style="padding:20px 16px;">
    <a-row :gutter="16">
      <a-col span="14">
        <a-card title="快捷方式">
          <ul class="shortcut">
            <!--自助机门店开单拆分为车辆开单、商品开单，隐藏次卡开单，次卡充值，菜单配置时也需要将次卡相关菜单去掉-->
            <li class="shortcut__item" @click="handleDiySelectGroup" v-if="this.$store.getters.getStoreInfo.diySwitch === 1">
              <a-icon class="shortcut__item--icon" type="form"></a-icon>
              <span class="shortcut__item--title">车辆开单</span>
            </li>
            <li class="shortcut__item" @click="handleDiyGoodOrder" v-if="this.$store.getters.getStoreInfo.diySwitch === 1">
              <a-icon class="shortcut__item--icon" type="form"></a-icon>
              <span class="shortcut__item--title">商品开单</span>
            </li>
            <li class="shortcut__item" @click="handleOrder" v-if="this.$store.getters.getStoreInfo.diySwitch === 0">
              <a-icon class="shortcut__item--icon" type="form"></a-icon>
              <span class="shortcut__item--title">开单</span>
            </li>
            <li class="shortcut__item" @click="handleTimesCardOrder" v-if="this.$store.getters.getStoreInfo.diySwitch === 0">
              <a-icon class="shortcut__item--icon" type="form"></a-icon>
              <span class="shortcut__item--title">次卡开单</span>
            </li>
            <li class="shortcut__item" @click="handleTimesCardRechargeAdd" v-if="this.$store.getters.getStoreInfo.diySwitch === 0">
              <a-icon class="shortcut__item--icon" type="form"></a-icon>
              <span class="shortcut__item--title">次卡充值</span>
            </li>
            <li class="shortcut__item" @click="handleMemberAdd">
              <a-icon class="shortcut__item--icon" type="crown"></a-icon>
              <span class="shortcut__item--title">添加会员</span>
            </li>
            <li class="shortcut__item" @click="handleRechargeAdd">
              <a-icon class="shortcut__item--icon" type="transaction"></a-icon>
              <span class="shortcut__item--title">会员充值</span>
            </li>
            <li class="shortcut__item" @click="() => this.balanceVisiable = true">
              <a-icon class="shortcut__item--icon" type="account-book"></a-icon>
              <span class="shortcut__item--title">查询余额</span>
            </li>
          </ul>
        </a-card>

        <a-card class="mt-20" title="今日最新订单"  >
          <!--<i-table :columns="columns" :dataSource="dataList">-->
          <!--</i-table>-->
          <a-table
            bordered
            :rowKey="record => record.orderId"
            :columns="columns"
            :scroll="{ x: 100 }"
            :data-source="dataList"
            :pagination="false">
          </a-table>
        </a-card>
      </a-col>
      <a-col span="10">
        <a-card title="排队信息">
          <div slot="extra">当前排队总数：{{queueCount}} </div>
          <a-table :columns="queueColumns" :data-source="queueData" bordered :pagination="false" :scroll="{y : 450}">
          </a-table>
        </a-card>
      </a-col>
    </a-row>

    <!--自助机门店开单拆分为车辆开单、商品开单，隐藏次卡开单，次卡充值，菜单配置时也需要将次卡相关菜单去掉-->
    <div v-if="this.$store.getters.getStoreInfo.diySwitch === 1">
      <!-- 创建自助机车辆组别弹出窗 开始 -->
      <create-diy-select-group-modal v-model="diySelectGroupVisible" @on-ok="handleToDiySelectGroup" @on-cancel="handleCancelDiySelectGroup"></create-diy-select-group-modal>
      <!-- 创建自助机车辆组别弹出窗 结束 -->

      <!-- 创建自助机选车弹出窗 开始 -->
      <create-diy-select-car-modal  v-model="diySelectCarVisible" @on-ok="handleToDiySelectCar" @on-cancel="handleCancelDiySelectCar" :data="diySelectCarData"></create-diy-select-car-modal>
      <!-- 创建自助机选车弹出窗 结束 -->

      <!-- 创建自助机商品订单弹出窗 开始 -->
      <create-diy-good-order-modal v-model="diyGoodOrderVisible" @on-ok="handleToDiyGoodOrder" @on-cancel="handleCancelDiyGoodOrder"></create-diy-good-order-modal>
      <!-- 创建自助机商品订单弹出窗 结束 -->
    </div>
    <div v-else>
      <!-- 创建订单弹出窗 开始 -->
      <create-order-modal v-model="orderVisible" @on-ok="handleToOrder" @on-cancel="handleCancelOrder"></create-order-modal>
      <!-- 创建订单弹出窗 结束 -->

      <!-- 创建次卡订单弹出窗 开始 -->
      <create-times-card-order-modal v-model="timesCardOrderVisible" @on-ok="handleToTimesCardOrder" @on-cancel="handleCancelTimesCardOrder"></create-times-card-order-modal>
      <!-- 创建次卡订单弹出窗 结束 -->

    </div>

    <!-- 收款弹出窗 开始 -->
    <payment-modal v-model="payVisible" @on-ok="handleToPay" @on-cancel="handleCancelPay" :data="payData"></payment-modal>
    <!-- 收款弹出窗 结束 -->

    <!-- 销售小票 开始 -->
    <order-bill ref="bill"></order-bill>
    <!-- 销售小票 结束 -->

    <!-- 自助机销售小票 开始 -->
    <diy-order-bill ref="diyBill"></diy-order-bill>
    <!-- 自助机销售小票 结束 -->

    <!-- 查询余额 开始-->
    <!--:confirm-loading="confirmLoading"-->
    <!--@ok="submitForm('ruleForm')"-->
    <a-modal
      title="查询余额"
      :visible="balanceVisiable"
      :destroyOnClose="true"
      @cancel="handleBalanceClose">
      <a-form-model ref="ruleForm" :model="ruleForm" :rules="rules" v-bind="layout">
        <a-form-model-item label="会员卡号" prop="mobile">
          <a-input-search
            placeholder="请输入会员卡号/手机号"
            allowClear
            v-model.trim="ruleForm.mobile"
            @change="handleSearch"
            @pressEnter="handleSearch">
          </a-input-search>
        </a-form-model-item>
        <div style="padding-left: 50px;">
          <a-descriptions title="会员信息" :column="1">
            <a-descriptions-item label="姓名">{{currentMember.realName || '-'}}</a-descriptions-item>
            <a-descriptions-item label="手机号">{{currentMember.mobile || '-'}}</a-descriptions-item>
            <a-descriptions-item label="会员卡号"> {{currentMember.memberCode || '-'}}</a-descriptions-item>
          </a-descriptions>
          <a-statistic title="当前余额" :precision="2" :value="currentMember.amount">
            <template #suffix>
              RM
            </template>
          </a-statistic>
        </div>
      </a-form-model>
      <div slot="footer">
        <a href="#" @click="handleBalanceClose">关闭</a>
      </div>
    </a-modal>
    <!-- 查询余额 结束-->
  </div>
</template>

<script>
import CreateOrderModal from '@/components/CreateOrderModal.vue';
import CreateTimesCardOrderModal from '@/components/CreateTimesCardOrderModal.vue';
import CreateDiySelectGroupModal from '@/components/CreateDiySelectGroupModal.vue';
import CreateDiyGoodOrderModal from '@/components/CreateDiyGoodOrderModal.vue';
import CreateDiySelectCarModal from '@/components/CreateDiySelectCarModal.vue';
import PaymentModal from '@/components/PaymentModal.vue';
import OrderBill from '@/components/print/OrderBill.vue';
import DiyOrderBill from '@/components/print/DiyOrderBill.vue';
import { orderList, queryPlayQueue, orderComplete } from '@/api/order';
import { getByCodeOrMobile } from '@/api/member';

export default {
  name: 'Home',
  components: {
    CreateTimesCardOrderModal,
    CreateDiySelectGroupModal,
    CreateDiyGoodOrderModal,
    CreateOrderModal,
    CreateDiySelectCarModal,
    PaymentModal,
    OrderBill,
    DiyOrderBill
  },
  props: {},
  data() {
    return {
      timesCardOrderVisible: false, // 次卡开单弹出层
      orderVisible: false, // 开单弹出层
      diySelectGroupVisible: false, // 自助机车辆组别弹出层
      diyGoodOrderVisible: false, // 自助机商品开单弹出层
      diySelectCarVisible: false, // 自助机选车弹出层
      payVisible: false, // 收款弹出层
      balanceVisiable: false, // 查询余额
      payData: {}, // 用于支付的订单信息
      diySelectCarData: {}, // 自助机门店选车界面信息
      timer: '',
      ruleForm: {},
      rules: {
        mobile: [
          {
            required: true,
            message: '请输入'
          }
        ],
      },
      currentMember: {},
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 },
      },
      columns: [
        {
          title: '订单号',
          dataIndex: 'orderId',
          key: 'orderId',
          ellipsis: true,
          width: 180,
        },
        {
          title: '姓名', // 会员显示名称 非会员显示散客
          dataIndex: 'memberName',
          key: 'memberName',
          width: 80,
          ellipsis: true,
        },
        {
          title: '订单金额',
          dataIndex: 'amount',
          key: 'amount',
          ellipsis: true,
          width: 100
        },
        // {
        //   title: '订单来源',
        //   dataIndex: 'orderSourceName',
        //   key: 'orderSourceName',
        //   ellipsis: true,
        //   width: 120,
        // },
        {
          title: '商品数量',
          dataIndex: 'itemCount',
          key: 'itemCount',
          ellipsis: true,
          width: 80
        },
        {
          title: '优惠金额',
          dataIndex: 'amountDiscount',
          key: 'amountDiscount',
          ellipsis: true,
          width: 80,
        },
        {
          title: '应付金额',
          dataIndex: 'amountPay',
          key: 'amountPay',
          width: 80,
          ellipsis: true,
        },
        {
          title: '退款金额',
          dataIndex: 'amountRefund',
          key: 'amountRefund',
          width: 80,
          ellipsis: true,
        },
        {
          title: '下单时间',
          dataIndex: 'orderTime',
          key: 'orderTime',
          width: 180,
          ellipsis: true,
        },
        {
          title: '订单状态',
          dataIndex: 'orderStatusName',
          key: 'orderStatusName',
          width: 80,
          fixed: 'right',
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          width: 100,
          scopedSlots: { customRender: 'action' },
          customRender: (text, row) => {
            return (
              <section>
                <a-button type="link" size="small" onClick={ () => this.handleToOrderInfo(row) }>查看</a-button>
              </section>
            );
          }
        }
      ],
      dataList: [],
      queueColumns: [
        {
          title: '',
          ellipsis: true,
          width: 20,
          customRender(text, item, index) {
            return index + 1;
          }
        },
        {
          title: '排队序号',
          dataIndex: 'playSN',
          key: 'playSN',
          width: 50,
          ellipsis: true,
        },
        {
          title: '车辆类别',
          dataIndex: 'kartClassifyName',
          key: 'kartClassifyName',
          width: 60,
          ellipsis: true,
        },
        {
          title: '下单时间',
          dataIndex: 'createTime',
          key: 'createTime',
          width: 90,
          ellipsis: true,
        },
        // {
        //   title: '操作',
        //   dataIndex: 'action',
        //   key: 'action',
        //   width: 60,
        //   ellipsis: true,
        //   scopedSlots: { customRender: 'action' },
        //   customRender: (text, row) => {
        //     return (
        //       <section>
        //         <a-button type="link" size="small" onClick={ () => this.handlePass(row) }>移出队列</a-button>
        //       </section>
        //     );
        //   }
        // }
      ],
      queueData: [],
      queueCount: 0,
    };
  },
  computed: {},
  watch: {},
  created() {
    console.log('进入主页');
    const { feeSwitch, diySwitch } = this.$store.getters.getStoreInfo;
    console.log(`自助机开关值：${diySwitch},会费开关值：${feeSwitch}`);
    this.getData();
    this.getQueue();
    this.timer = setInterval(() => {
      this.getQueue();
    }, 30000);
  },
  mounted() {
  },
  destroyed() {
    clearInterval(this.timer);
  },
  methods: {
    // 开单按钮(非自助机门店)
    handleOrder() {
      // console.log('非自助机门店开单按钮');
      this.orderVisible = true;
    },
    // 次卡开单按钮(非自助机门店)
    handleTimesCardOrder() {
      // console.log('非自助机门店次卡开单按钮');
      this.timesCardOrderVisible = true;
    },
    // 车辆开单按钮(自助机门店，将开单拆分为车辆开单，商品开单)
    handleDiySelectGroup() {
      // console.log('自助机车辆组别');
      this.diySelectGroupVisible = true;
    },
    // 商品开单按钮(自助机门店，将开单拆分为车辆开单，商品开单)
    handleDiyGoodOrder() {
      // console.log('自助机商品开单');
      this.diyGoodOrderVisible = true;
    },
    // 提交非自助机订单
    handleToOrder(data) {
      // console.log('提交非自助机订单-》订单支付-》传输数据到订单支付');
      this.payData = data;
      this.orderVisible = false;
      this.payVisible = true;
    },
    // 取消非自助机订单
    handleCancelOrder() {
      // console.log('取消非自助机订单');
      this.orderVisible = false;
    },
    // 提交非自助机次卡订单
    handleToTimesCardOrder(data) {
      // console.log('提交非自助机次卡订单-》订单支付-》传输数据到订单支付');
      this.payData = data;
      // 模拟打印
      this.$nextTick(() => {
        this.$refs.bill.handlePrintBill(this.payData.orderId);
      });
      this.getData();
      this.getQueue();
    },
    // 取消非自助机次卡订单
    handleCancelTimesCardOrder() {
      this.timesCardOrderVisible = false;
    },
    // 车辆组别提交-》打开车辆选择界面
    handleToDiySelectGroup(data) {
      // console.log('车辆组别提交-》打开车辆选择界面-》传递数据到车辆选择界面', data);
      this.diySelectCarData = data;
      this.diySelectGroupVisible = false;
      this.diySelectCarVisible = true;
    },
    // 取消自助机车辆组别选择
    handleCancelDiySelectGroup() {
      // console.log('取消自助机车辆组别选择');
      this.diySelectGroupVisible = false;
    },
    // 提交自助机订单
    handleToDiySelectCar(data) {
      console.log('提交自助机订单-》订单支付-》传输数据到订单支付', data);
      this.payData = data;
      this.diySelectCarVisible = false;
      this.payVisible = true;
    },
    // 取消自助机订单
    handleCancelDiySelectCar() {
      // console.log('取消自助机订单');
      this.diySelectCarVisible = false;
    },
    // 提交自助机商品订单
    handleToDiyGoodOrder(data) {
      console.log('提交自助机商品订单-》自助机商品订单支付-》传输数据到订单支付', data);
      this.payData = data;
      this.diyGoodOrderVisible = false;
      this.payVisible = true;
    },
    // 取消自助机商品订单
    handleCancelDiyGoodOrder() {
      // console.log('取消自助机商品订单');
      this.diyGoodOrderVisible = false;
    },
    // 付款
    handleToPay() {
      this.payVisible = false;
      // 模拟打印
      if (this.$store.getters.getStoreInfo.diySwitch === 1) {
        console.log('自助机订单打印');
        this.$nextTick(() => {
          this.$refs.diyBill.handlePrintBill(this.payData.orderId);
        });
      } else {
        console.log('非自助机订单打印');
        this.$nextTick(() => {
          this.$refs.bill.handlePrintBill(this.payData.orderId);
        });
      }
      this.getData();
      this.getQueue();
    },

    // 取消付款
    handleCancelPay() {
      this.$message.warning('未完成支付，请到订单列表中重新付款');
      this.getData();
    },
    handleMemberAdd() {
      this.$router.push({
        name: 'memberAdd'
      });
    },
    handleRechargeAdd() {
      this.$router.push({
        name: 'rechargeAdd'
      });
    },
    handleTimesCardRechargeAdd() {
      this.$router.push({
        name: 'timesCardRechargeAdd'
      });
    },

    // 查询余额
    handleSearch(e) {
      const searchText = e.target.value;
      if (searchText.length < 8) {
        return;
      }
      getByCodeOrMobile(searchText).then((data) => {
        this.currentMember = data || {};
      });
    },

    // 查看订单信息
    handleToOrderInfo(row) {
      this.$router.push({
        name: 'orderInfo',
        params: {
          id: row.orderId
        }
      });
    },
    // 设置过号
    handlePass(order) {
      const self = this;
      this.$confirm({
        title: '移除队列',
        content: `此操作将要把${order.playSN}从排队序列中移出，您确定要这么做吗？`,
        okType: 'danger',
        onOk() {
          return new Promise((resolve, reject) => {
            const params = {
              orderKartPlayId: order.orderKartPlayId
            };

            orderComplete(params).then(() => {
              self.$message.success('移除成功');
              self.getQueue();
              resolve();
            }).catch(() => {
              reject();
            });
          }).catch((e) => console.log(e));
        },
        onCancel() {},
      });
    },
    // 获取订单列表
    getData() {
      const moment = require('moment');
      const params = {
        pageIndex: 1,
        pageSize: 10,
        startDate: moment().format('YYYY-MM-DD 00:00:00'),
        endDate: moment().format('YYYY-MM-DD 23:59:59'),
      };
      // console.log(params)
      this.dataList = [];
      orderList(params).then((data) => {
        const { list } = data;
        list.forEach((item) => {
          item.key = item.orderId;
        });
        this.dataList = list;
      });
    },
    // 排队列表
    getQueue() {
      const params = {
        pageIndex: 1,
        pageSize: 400,
      };
      queryPlayQueue(params).then((data) => {
        const { totalCount, list } = data;
        if (list) {
          list.forEach((item) => {
            item.key = item.orderKartPlayId;
          });
          this.queueCount = totalCount;
          this.queueData = list;
        }
      });
    },
    handleBalanceClose() {
      this.$refs.ruleForm.resetFields();
      this.currentMember = {};
      this.balanceVisiable = false;
    }
  },
};
</script>

<style lang="less" scoped>
@import "../../style/modules/variables";
.count-style{
  font-size: 50px;
}

.shortcut {
  display: flex;
  justify-content: space-around;
  padding: 20px 0;
}
.shortcut__item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  width: 100px;
  height: 100px;
  border-radius: 5px;
  background-color: #f8f8f8;
  border: 1px solid @border-color-base;
  cursor: pointer;
  user-select: none;

  &:hover {
    background: @primary-color;
    .shortcut__item--icon,
    .shortcut__item--title {
      color: #fff;
    }
  }
}
.shortcut__item--icon {
  font-size: 40px;
}
.shortcut__item--title {
  display: block;
  margin-top: 10px;
  font-size: 16px;
}
</style>
